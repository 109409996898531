import React from 'react';
import Layout from '../../../components/layout';
import { AltWrapper, Title, TileWrapper } from '../../../styles/commonStyles';
import TargetFail from '../../../assets/svgs/target-fail.svg';
import styled from 'styled-components';

const StyledTargetFail = styled(TargetFail)`
  margin: auto;
  max-width: 180px;
`;

const EmailExpired: React.FunctionComponent = () => {
  return (
    <Layout>
      <AltWrapper>
        <div>
          <TileWrapper>
            <Title>Your link has expired!</Title>
            <StyledTargetFail />
          </TileWrapper>
          <p>
            If this is the email that you registered with Bó then you should
            receive an email containing a login link. Please note the link will
            expire in 15 minutes.
          </p>
        </div>
      </AltWrapper>
    </Layout>
  );
};

export default EmailExpired;
